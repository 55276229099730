:root {
  --theme-color-red: #eb1c24;
}
.average-cost p {
    font-size: 16px;
}

.restaurant-info-section-list {
    columns: 2 200px;
}

.restaurant-info-section-list li {
    margin-bottom: 1rem;
    position: relative;
    padding-left: 1.5rem;
    font-size: 16px;
}

.restaurant-info-section-list li::before {
    content: "\f058";
    position: absolute;
    left: 0;
    top: 0;
    font-family: "Font Awesome\ 5 Free";
    color: #eb1c24;
    font-size: 18px;
}

.btn.btn-outline-danger:hover svg {
    color: #fff !important;
}

.font-2x {
    font-size: 16px !important;
}

.quick-contact {
    margin-top: 35px;
}

.social-block a {
    margin-right: 10px;
    padding: 5px;
    border-radius: 5px;
}

.social-share.social-share {
    font-size: 25px;
    color: #eb1c24;
}

.rating-star {
    font-size: 18px;
    color: #eb1c24;
}

.rating-block {
    display: flex;
    background-color: #f9f9f9;
    border-radius: 5px;
    padding: 5px 10px;
    align-items: center;
    gap: 5px;
    width: fit-content;
    margin-bottom: 0.5rem;
    font-weight: bold;
    font-size: 18px;
}

.like-block {
    display: flex;
    background-color: #f9f9f9;
    border-radius: 5px;
    padding: 5px 10px;
    align-items: center;
    gap: 5px;
    width: fit-content;
    margin-bottom: 0.5rem;
    font-weight: bold;
    font-size: 18px;
    margin-right: 0.5rem;
}

.reviews-block {
    display: flex;
    background-color: #f9f9f9;
    border-radius: 5px;
    padding: 5px 10px;
    align-items: center;
    gap: 5px;
    width: fit-content;
    margin-bottom: 0.5rem;
    font-weight: bold;
    font-size: 18px;
    margin-left: 0.5rem;
}

.social-share.action-btn {
    margin-right: 5px;
}

.social-share.action-btn.rotate-icon {
    transform: rotate(-35deg);
}

.contact-restaurant-name {
    font-size: 20px;
    font-weight: bold;
}

.contact-address {
    display: flex;
    gap: 10px;
}

.contact-mobile {
    display: flex;
    gap: 10px;
}

.contact-detail {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.contact-detail div:first-child {
    min-width: 60px;
    /* font-weight: 600; */
    color: #666;
}

.open,
.closed {
    width: 80px;
    height: auto;
}

.offcanvas.offcanvas-end.review-canvas {
    width: 650px;
}

.review-profile-pic {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    margin-right: 10px;
}

.review-canvas .like-block,
.review-canvas .rating-block {
    font-size: 12px;
}

.upload-image {
    /* height: 350px; */
    width: 100%;
    aspect-ratio: 1/1 !important;
    background-color: var(--color-light);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.upload-image input {
    height: 350px;
    width: 350px;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload-image input:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 330px;
    height: 330px;
    opacity: 0;
    transition: 0.5s ease;
    background: #fff url(../../assets/images/upload.png) center no-repeat;
    border-radius: 1rem;
    cursor: pointer;
    transition: 0.3s;
}

.upload-image.logo {
    height: 185px;
    width: 350px;
    background-color: var(--color-light);
    display: flex;
    justify-content: center;
    align-items: center;
}

.upload-image img.rounded {
    height: 100%;
    object-fit: cover;
}

.upload-imge-btn {
    font-size: 36px;
    margin-bottom: 10px;
}

.image-overlay {
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
}

.facilities-selection .form-check-input {
    position: absolute;
    top: 5px;
    right: 25px;
    box-shadow: none;
    cursor: pointer;
    height: 25px;
    width: 25px;
}

.facilities-selection .form-check-input:focus {
    border-color: var(--theme-color-red);
    outline: 0;
    box-shadow: none;
}

.facilities-selection .form-check-input:checked {
    background-color: var(--theme-color-red);
    border-color: var(--theme-color-red);
}

.social-media-link-update {
    position: absolute;
    background-color: #fff;
    border: 1px solid #dee2e6;
    padding: 5px;
    border-radius: 5px;
    top: 185px;
    min-width: 420px;
    z-index: 1;
}

.working-hours-dropdown.social-media-link-update {
    position: absolute;
    background-color: #fff;
    border: 1px solid #dee2e6;
    padding: 5px;
    border-radius: 5px;
    top: 340px;
    left: 53%;
    min-width: 420px;
    z-index: 1;
}

.arrow-close {
    color: var(--themecolor-white);
    position: absolute;
    top: 0;
    right: 0;
    font-size: 1.4rem;
    background: var(--theme-color-red);
    border-radius: 0 .25rem 0 0.375rem;
    display: flex;
    align-items: center;
    padding: 2px;
    cursor: pointer;
}

.add-restaurant-upload-btn input[type="file"] {
    display: block !important;
    z-index: 999 !important;
}

.arrow-top {
    background-color: var(--themecolor-white);
    border-top: 1px solid #dee2e6;
    border-left: 1px solid #dee2e6;
    height: 10px;
    width: 10px;
    rotate: 45deg;
    position: absolute;
    top: -6px;
    left: 12px;
}

.right-10 .arrow-top {
    background-color: var(--themecolor-white);
    border-top: 1px solid #dee2e6;
    border-left: 1px solid #dee2e6;
    height: 10px;
    width: 10px;
    rotate: 45deg;
    position: absolute;
    top: -6px;
    left: auto;
    right: 22px;
}

.right-10,
.right-10-whatsapp,
.right-10-linkedin {
    right: 10px !important;
}

.right-10-linkedin .arrow-top {
    background-color: var(--themecolor-white);
    border-top: 1px solid #dee2e6;
    border-left: 1px solid #dee2e6;
    height: 10px;
    width: 10px;
    rotate: 45deg;
    position: absolute;
    top: -6px;
    left: auto;
    right: 335px;
}

.right-10-whatsapp .arrow-top {
    background-color: var(--themecolor-white);
    border-top: 1px solid #dee2e6;
    border-left: 1px solid #dee2e6;
    height: 10px;
    width: 10px;
    rotate: 45deg;
    position: absolute;
    top: -6px;
    left: auto;
    right: 290px;
}

.restaurant-save-btn {
    width: 25%;
}

.large-image {
    position: relative;
}

.upload-image-component .restaurant-image-cards {
    width: 350px;
    height: 350px;
    position: relative;
}

.upload-image .gallary-upload-btn {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.upload-image-component:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 90%;
    opacity: 0;
    transition: 0.5s ease;
    background: #fff url(../../assets/images/upload.png) center no-repeat;
    border-radius: 1rem;
    cursor: pointer;
    background-size: contain;
}

input[type="file"] {
    display: none !important;
    transition: 0.3s;
}

.upload-image-component:hover input[type="file"] {
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 2rem;
}

.upload-image-component:hover input:before {
    opacity: 1;
}

.working-hours .time-select {
    width: 110px;
}

.working-hours p {
    width: 120px;
}

.ant-picker:hover,
.ant-space-item .ant-picker-focused {
    border-color: var(--theme-color-red) !important;
    box-shadow: none !important;
}

.working-hours .form-check-input:checked {
    background-color: var(--theme-color-red) !important;
    border: none !important;
}

.working-hours .form-check-input:focus {
    border-color: var(--theme-color-red) !important;
    box-shadow: none !important;
}

.ant-btn-primary {
    background-color: var(--theme-color-red) !important;
}

.add-image {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}

/* .add-image svg {
  color: var(--themecolor-white);
} */

.crop-input input[type="file"] {
    display: block !important;
}

.upload-image .restuarant-docs-p,
.upload-image .restuarant-docs {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fffcfc5e;
    width: 100%;
    height: 100%;
    display: none;
    /* opacity: 0; */
    transition: 1s;
}

.upload-image .restuarant-docs-p {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    flex-direction: column;
}

.upload-image:hover .restuarant-docs-p {
    /* display: none; */
}

.add-restaurant-upload-btn {
    bottom: 1rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.add-restaurant-upload-btn button {
    width: max-content;
}

.upload-image:hover .restuarant-docs {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 1s;
    /* opacity: 1; */
}
.restaurant-type-wrap-addrestaurant h6 {
    font-size: 16px;
}
.restaurant-type-wrap-addrestaurant .item .services-icon {
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s,-webkit-transform .3s;
}
.restaurant-type-wrap-addrestaurant .item h6 {
    transition: -webkit-transform .8s;
    transition: transform .8s;
    transition: transform .8s,-webkit-transform .8s;
    padding-top: 5px;
}


.restaurant-type-wrap-addrestaurant .item:hover .services-icon {
    -webkit-transform: translateY(-10%) scale(1.15);
    transform: translateY(-10%) scale(1.15);
}

.croper-main .cropper-modal {
    background-color: #dee2e6 !important;
}
.main-images{
    top: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
}
/* .cropper-crop-box , .cropper-canvas,  .cropper-canvas img{
    height: 100% !important;
    width: 100% !important;
} */

/* .croper-main .cropper-canvas img,
.croper-main .cropper-canvas {
    width: 100% !important;
    height: 100% !important;
    transform: translate(0) !important;
} */

.add-restaurant-upload-icon img {
    width: 90px;
    height: 90px;
    margin: 0 auto;
}

.add-restaurant-sm-icon .btn-danger {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    margin-left: 5px;
}

.ant-switch-inner {
    background: var(--theme-color-red) !important;
}

.restaurant-type-wrap-addrestaurant {
    width: calc(100% / 8);
    margin-bottom: 2rem;
}

@media screen and (max-width: 768px) {
    .social-block a:first-child {
        margin-left: 10px;
        padding: 5px;
        border-radius: 5px;
        margin-top: 10px;
    }

    .btn {
        width: 100%;
    }

    .restaurant-type-wrap-addrestaurant {
        width: calc(100% / 2);
    }

    .cuisine-type-wrap.cuisine-type-wrap {
        width: calc(100% / 2);
    }

    .cuisines .cuisine-type-wrap.cuisine-type-wrap {
        width: calc(100% / 4);
    }

    .upload-image {
        height: 250px;
        width: 100%;
        padding: 0px;
    }

    .upload-image.logo {
        height: 200px;
        width: 100%;
        padding: 20px;
        margin-bottom: 20px;
    }

    .restaurant-save-btn {
        width: 100%;
    }

    .social-media-link-update {
        min-width: auto;
        left: 13px;
        right: 13px;
        top: 100%;
        width: 92%;
    }

    .arrow-top {
        display: none;
    }

    .social-share.action-btn.rotate-icon {
        margin: 0;
    }

    .add-restaurant-upload-icon img {
        margin-bottom: 1rem;
    }

    .form-floating {
        margin-top: 1rem;
    }

    .upload-image .restuarant-docs-p {
        /* justify-content: flex-start; */
        margin-top: -1rem;
    }

    .border-left.border-danger.border-2 {
        border: none !important;
    }
}

.average-cost p {
    font-size: 16px;
}

.restaurant-info-section-list {
    columns: 2 200px;
}

.restaurant-info-section-list li {
    margin-bottom: 1rem;
    position: relative;
    padding-left: 1.5rem;
    font-size: 16px;
}

.restaurant-info-section-list li::before {
    content: "\f058";
    position: absolute;
    left: 0;
    top: 0;
    font-family: "Font Awesome\ 5 Free";
    color: #eb1c24;
    font-size: 18px;
}

.btn.btn-outline-danger:hover svg {
    color: #fff !important;
}

.font-2x {
    font-size: 16px !important;
}

.quick-contact {
    margin-top: 35px;
}

.social-block a {
    margin-right: 10px;
    padding: 5px;
    border-radius: 5px;
    align-items: center;
    height: 40px;
}

.social-share.social-share {
    font-size: 25px;
    color: #eb1c24;
}

.social-media-share.social-media-share {
    cursor: pointer;
    font-size: 25px;
    color: #b9b4b5;
}

.rating-star {
    font-size: 18px;
    color: #eb1c24;
}

.rating-block {
    display: flex;
    background-color: #f9f9f9;
    border-radius: 5px;
    padding: 5px 10px;
    align-items: center;
    gap: 5px;
    width: fit-content;
    margin-bottom: 0.5rem;
    font-weight: bold;
    font-size: 18px;
}

.like-block {
    display: flex;
    background-color: #f9f9f9;
    border-radius: 5px;
    padding: 5px 10px;
    align-items: center;
    gap: 5px;
    width: fit-content;
    margin-bottom: 0.5rem;
    font-weight: bold;
    font-size: 18px;
    margin-right: 0.5rem;
}

.reviews-block {
    display: flex;
    background-color: #f9f9f9;
    border-radius: 5px;
    padding: 5px 10px;
    align-items: center;
    gap: 5px;
    width: fit-content;
    margin-bottom: 0.5rem;
    font-weight: bold;
    font-size: 18px;
    margin-left: 0.5rem;
}

.social-share.action-btn {
    margin-right: 5px;
}

.social-share.action-btn.rotate-icon {
    transform: rotate(-35deg);
}

.contact-restaurant-name {
    font-size: 25px;
    font-weight: bold;
}

.contact-address {
    display: flex;
    gap: 10px;
}

.contact-mobile {
    display: flex;
    gap: 10px;
}

.contact-detail {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.contact-detail div:first-child {
    min-width: 60px;
    /* font-weight: 600; */
    color: #666;
}

.open,
.closed {
    width: 80px;
    height: auto;
}

.offcanvas.offcanvas-end.review-canvas {
    width: 650px;
}

.review-profile-pic {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    margin-right: 10px;
}

.review-canvas .like-block,
.review-canvas .rating-block {
    font-size: 12px;
}

.menu-image-overlay::before {
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
}

.skeleteon-image-height {
    height: 330px !important;
}

.menu-image-cover-fit {
    object-fit: cover;
    height: inherit;
}

.action-button-wrap {
    display: flex;
}

.restaurant-type-wrap-addrestaurant .services-icon {
    width: 100%;
    margin-bottom: 10px;
}

.restaurant-type-wrap-addrestaurant .item {
    padding: 0 !important;
    padding-bottom: 10px !important;
    /* min-height: 100% !important; */
    margin: 0 !important;
}

.facilities-type-wrap .item {
    padding: 1rem;
    padding-bottom: 10px;
}

.facilities-type-wrap .services-icon {
    margin-top: 1rem;
    width: 50%;
}

@media screen and (max-width: 992px) {
    .restaurant-type-wrap-addrestaurant {
        width: calc(100% / 3);
    }
}

@media screen and (max-width: 768px) {
    .social-block a:first-child {
        margin-right: 10px !important;
        margin-left: 0;
        padding: 5px;
        border-radius: 5px;
        margin-right: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .btn {
        width: 100%;
    }

    .restaurant-type-wrap-addrestaurant {
        width: calc(100% / 2);
    }

    .cuisine-type-wrap.cuisine-type-wrap {
        width: calc(100% / 2);
    }

    .action-button-wrap {
        display: flex;
        flex-wrap: wrap;
    }

    .social-block a {
        /* width: 40%; */
        width: 47px !important;
        height: 47px;
        align-items: center;
        justify-content: center;
    }

    .social-block a:last-child {
        /* height: 100%; */
    }

    .social-block .social-action {
        width: auto;
    }

    .btn {
        width: auto;
    }

    .border-start.border-danger{
        border: none !important;
    }
    
}

.restaurant-type-wrap-addrestaurant .services .item {
    padding: 0 !important;
}

.services .item.selected .bg-white {
    background: var(--themecolor-white);
}

.services .item.selected {
    margin: 0 !important;
    background: var(--theme-color-red);
    color: var(--themecolor-white);
    border-radius: .372rem;
}

.save-btn-bottom-fixed {
    position: fixed;
    width: auto;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
    transition: .5s;
    background: var(--themecolor-white);
}

.bodycollapse .save-btn-bottom-fixed {
    margin-left: 15.8rem;
    transform: .5s;
}

.border-danger {
    border-color: var(--theme-color-red) !important;
}

@media (max-width:568px) {
    .save-btn-bottom-fixed {
        margin-left: 0;
    }
    .bodycollapse .save-btn-bottom-fixed{
        margin-left: 11.7rem;
    }
    .social-media-link-update {
        top: 76%;
    }
    .working-hours-dropdown.social-media-link-update {
        top: inherit;
        left: auto;
        bottom: -42%;
        min-width: 90%;
        /* margin: 0 1rem; */
    }
    
}
.restaurant-name-input{
    position: relative;
}
.restaurant-name-ibutton{
    font-size: 20px;
    text-align: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    cursor: pointer;
    right: 15px;
}
.custom-tooltip {
  background-color: red !important;
  color: white;
}
.ant-upload-list-item-container{
    margin-left: 1rem;
}

.profile-view .btn-close{
    filter: none !important;
  }
.thankyou-img  img{
    width: 113px;
}
.theme-color{
    color: var(--theme-color-red);
}
.restuarant-docs-p .progress{
    --bs-progress-bar-bg:var(--theme-color-red) !important
}
.popup-small-images{
    position: relative;
}
.popup-small-images 
.img-delete-btn {
    border-top-right-radius: 5px;
}
.img-delete-btn svg{
    margin-top: -4px;
}
.img-delete-btn{
  border: none;
  font-size: 20px;
  padding: 0 3px;
  height: 30px;
  align-items: center;
  justify-content: center;
  background-color: var(--theme-color-red);
  color: var(--themecolor-white);
  position: absolute;
  right: 0;
  border-radius: 0 0 0 5px;
}