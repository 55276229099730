.thankyou-img img{
    width: 40%;
}
.thankyou-modal p{
    text-align: justify;
}

.thankyou-modal .bottom-two-text h6{
    font-weight: 600;
    font-size: 1.4rem;
    margin-bottom: 0;
}
.thankyou-modal h4 span{
    color: var(--theme-color-red);
}
.thankyou-btn{
    margin-top: 3rem;
}