body {
  margin: 0;
  padding: 0;
  background-color: #fafafa;
  font: 11pt "Tahoma";
}
* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
p {
    color: #000;
}
.header {
    min-height: unset;
}
.page {
  width: 21cm;
  min-height: 29.7cm;
  padding: 1cm;
  margin: 1cm auto;
  border: 1px #d3d3d3 solid;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
.subpage {
  padding: 0cm;
  border: 0px #d3d3d3 solid;
  height: 256mm;
  width: 100mm;
  margin: 0 auto;
}

.website-info {
    text-transform: lowercase;
}

.subpage .header {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 15pt;
}
.subpage .header p {
  margin-top: 0;
  margin-bottom: 5pt;
}

.subpage .contact-details {
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 15pt;
}

.subpage .contact-details p {
  margin-top: 0;
  margin-bottom: 0pt;
}

.subpage .contact-details .govt-info {
  text-transform: uppercase;
}

.subpage .invoice-details {
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 15pt;
}

.subpage .invoice-details p {
  margin-top: 0;
  margin-bottom: 5pt;
}

.subpage .invoice-details .left {
    text-align: left;
}

.subpage .invoice-details .govt-info {
  text-transform: uppercase;
}

.item-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 15pt;
}

.item-table tr th {
   border-top: 1px solid #000;
   border-bottom: 1px solid #000;
}

.item-table tr th,.item-table tr td {
    padding: 5px 0;
}

.item-table tr th:first-child,.item-table tr td:first-child {
    text-align: left;
        vertical-align: baseline;
}

.item-table tr th:nth-child(n+2), .item-table tr td:nth-child(n+2) {
    text-align: right;
        vertical-align: baseline;
}

.barcode-details {
    text-align: center;
  text-transform: capitalize;
margin-bottom: 15pt;
}

.barcode {
    width: 50%;
}

@page {
  size: A4;
  margin: 0;
}
@media print {
  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
}
