.average-cost p {
  font-size: 16px;
}
.restaurant-info-section-list {
  columns: 2 200px;
}
.restaurant-info-section-list li {
  margin-bottom: 1rem;
  position: relative;
  padding-left: 1.5rem;
  font-size: 16px;
}
.restaurant-info-section-list li::before {
  content: "\f058";
  position: absolute;
  left: 0;
  top: 0;
  font-family: "Font Awesome\ 5 Free";
  color: #eb1c24;
  font-size: 18px;
}

.btn.btn-outline-danger:hover svg {
  color: #fff !important;
}

.font-2x {
  font-size: 16px !important;
}

.quick-contact {
  margin-top: 35px;
}

.social-block a {
  margin-right: 10px;
  padding: 5px;
  border-radius: 5px;
}

.social-share {
  font-size: 36px;
  color: #eb1c24;
}

.rating-star {
  font-size: 18px;
  color: #eb1c24;
}

.rating-block {
  display: flex;
  background-color: #f9f9f9;
  border-radius: 5px;
  padding: 5px 10px;
  align-items: center;
  gap: 5px;
  width: fit-content;
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-size: 18px;
}

.like-block {
  display: flex;
  background-color: #f9f9f9;
  border-radius: 5px;
  padding: 5px 10px;
  align-items: center;
  gap: 5px;
  width: fit-content;
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-size: 18px;
  margin-right: 0.5rem;
}

.social-share.action-btn {
  margin-right: 5px;
}

.social-share.action-btn.rotate-icon {
  transform: rotate(-35deg);
}

.contact-restaurant-name {
  font-size: 20px;
  font-weight: bold;
}

.contact-address {
  display: flex;
  gap: 10px;
}

.contact-mobile {
  display: flex;
  gap: 10px;
}

.contact-detail {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.contact-detail div:first-child {
  min-width: 60px;
  font-weight: 600;
  color: #666;
}
