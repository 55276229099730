body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-select-selector {
  border: none !important;
  text-align: left !important;
}

.ant-select-selector:focus-visible {
  box-shadow: none !important;
  border: none !important;
}

@media (max-width:360px) {
  .signup-phn-view {
    font-size: 17px !important;
  }
}

@media (max-width:342px) {
  .small-view-padding {
    padding: 0 !important;
  }
}