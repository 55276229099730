@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

:root {
  --theme-color-red: #eb1c24;
  --themefont: "Raleway", sans-serif;
  --themecolor-white: #ffffff;
  --themecolor-gray: #eeeeee;
  --themecolor-black: #000000;
  --theme-color-veg: #178138;
  --theme-color-nonveg: #bc0612;
  --theme-color-vegan: #008000;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: none !important;
  list-style: none;
}

body {
  color: #00000a;
  line-height: 1.3;
  font-weight: 400;
  font-size: 14px;
  font-family: var(--themefont);
  overflow-x: hidden !important;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", sans-serif;
}

h6 {
  font-size: 18px;
  line-height: 26px;
}

p {
  font-size: 14px;
  color: #666;
  line-height: 1.6;
  word-spacing: 1px;
  margin: 0;
}

img {
  width: 100%;
  height: auto;
}

.form-control:focus {
  box-shadow: none !important;
  border-color: var(--theme-color-red) !important;
}

span,
a,
a:hover {
  display: inline-block;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.btn-danger.btn-danger {
  background-color: var(--theme-color-red);
  border-color: var(--theme-color-red);
}

.cursor-pointer {
  cursor: pointer;
}

.connect {
  background: #000000;
  border: 1px solid #000000;
  color: #fff;
  padding: 10px 30px;
  margin-top: 15px;
  font-weight: 600;
  cursor: pointer;
  position: relative;
  border-radius: 5px;
}

.connect:after {
  content: "";
  width: 0;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 100%;
  z-index: 1;
  background: #fff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.connect:hover {
  background: #000000;
  color: #fff;
}

.section-head {
  margin-bottom: 16px;
}

.section-head h4 {
  position: relative;
  padding: 0 0 0 15px;
  line-height: 1;
  border-left: 1px solid #eee;
  letter-spacing: 2px;
  font-size: 38px;
  font-weight: 700;
}

.section-head h4:before {
  content: "";
  width: 1px;
  height: 35px;
  background: #eb1c24;
  position: absolute;
  left: -1px;
  bottom: 0;
}

.section-head h4 span {
  font-weight: 200;
  padding-bottom: 5px;
}

.section-head .description {
  padding-bottom: 5px;
}

.text-large {
  font-size: 36px;
}

.text-red {
  color: var(--theme-color-red) !important;
}

.bg-gray {
  background: #f7f7f7;
}

.bg-gray .owl-theme .owl-dots .owl-dot span {
  background: #777;
}

.o-hidden {
  overflow: hidden;
}

.position-re {
  position: relative;
}

.full-width {
  width: 100% !important;
}

.bg-img {
  background-size: cover;
  background-repeat: no-repeat;
}

.section-padding {
  padding: 50px 0;
}

.valign {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.v-middle {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  -webkit-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
}

.cd-headline {
  font-size: 3rem;
  line-height: 1.2;
}

.filter-row.filter-row {
  display: flex;
  width: 85%;
  gap: 10px;
  overflow-x: auto;
  align-items: center;
  padding-bottom: 15px;
  padding-top: 5px;
  flex-wrap: nowrap;
}

.filter-row .nav-link {
  text-wrap: nowrap;
}

.drag-icon {
  display: none;
}

.digital-menu-item-img {
  width: 75px;
  margin-right: 10px;
}

.menu-item-title {
  width: 90%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.menu-item-name-wrapper {
  max-width: 70%;
}

.offer-image {
  border-radius: 10px;
  overflow: hidden;
}

.react-multi-carousel-list {
  padding-bottom: 25px;
}

.react-multi-carousel-dot-list {
  position: absolute;
  bottom: 0;
  display: flex;
  left: 0;
  right: 0;
  justify-content: center;
  margin: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.react-multi-carousel-dot button {
  border-color: var(--theme-color-red) !important;
}

.react-multi-carousel-dot--active button {
  background: var(--theme-color-red) !important;
}

@media screen and (max-width: 768px) {
  .w-75.w-75 {
    width: 100% !important;
  }

  .drag-icon {
    position: absolute;
    width: 50px;
    right: 7px;
    top: 10%;
    display: block;
  }

  .menu-item-name-wrapper {
    max-width: 60%;
  }
}

@media only screen and (min-width: 768px) {
  .cd-headline {
    font-size: 4.4rem;
    font-weight: 300;
  }
}

.item-card-wrapper:nth-child(even) {
  border-left: 1px solid var(--theme-color-red);
}

.item-card-wrapper:nth-child(odd) {
  border-left: 1px solid var(--themecolor-white);
}

.item-card {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  overflow: hidden;
}

.digital-menu .item-card {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  overflow: hidden;
  flex-direction: row;
}

.item-card img {
  border-radius: 10px;
}

.recipe-img {
  width: 200px;
  height: auto;
}

.recipe-best {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.best-seller {
  background-color: chocolate;
  border-radius: 4px;
  padding: 0 4px;
  color: var(--themecolor-white);
  text-transform: capitalize;
}

.egg-recipe {
  background-color: maroon;
  border-radius: 4px;
  padding: 0 4px;
  color: var(--themecolor-white);
  text-transform: capitalize;
}

.customisable {
  background-color: #0aa3c2;
  border-radius: 4px;
  padding: 0 4px;
  color: var(--themecolor-white);
  text-transform: capitalize;
}

.recipe-name {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.recipe-name a {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  color: var(--theme-text-black);
  margin-top: 5px;
}

.recipe-price {
  margin-bottom: 0;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.recipe-price svg {
  font-size: 13px;
}

.recipe-category {
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 600;
}

.recipe-category span {
  font-style: italic;
}

.recipe-description {
  margin-bottom: 5px;
  font-size: 12px;
}

.recipe-description.special-offer {
  font-size: 14px;
}

.recipe-type.recipe-type {
  width: 20px;
  border-radius: 0;
}

.mild-chilli {
  font-size: 20px;
  color: green;
}

.medium-chilli {
  font-size: 20px;
  color: orangered;
}

.hot-chilli {
  font-size: 20px;
  color: red;
}

.star-rating {
  color: gold;
}

.wishlist-share {
  color: var(--theme-color-red);
  font-size: 16px;
}

.flex-div {
  flex: 1 1;
}

.item-category-filter {
  border: 2px solid;
  border-radius: 10px;
  width: 100%;
  display: inline-block;
  height: 58px;
  text-decoration: none;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  gap: 5px;
  max-width: 120px;
  cursor: pointer;
}

.food-type-filter-row {
  overflow-y: hidden;
  overflow-x: auto;
  flex-wrap: nowrap;
  gap: 15px;
  margin: 0;
  padding-bottom: 0px;
}

.item-category-filter.veg {
  border-color: var(--theme-color-veg);
  color: var(--theme-color-veg);
}

.item-category-filter.nonveg {
  border-color: var(--theme-color-nonveg);
  color: var(--theme-color-nonveg);
}

.item-category-filter.vegan {
  border-color: var(--theme-color-vegan);
  color: var(--theme-color-vegan);
}

.item-category-filter.nonveg.filter {
  border-color: var(--theme-color-red);
  color: var(--theme-color-red);
  padding: 0 10px;
}

.item-category-filter.nonveg.filter svg {
  font-size: 24px;
  color: var(--theme-color-red);
}

.sticky-search-bar {
  position: sticky;
  top: 80px;
  z-index: 10;
  transition: all ease-in-out 1s;
}

.sticky-search-bar .container-fluid {
  padding: 0;
}

.sticky-search-bar .card {
  border-radius: 0;
}

@media only screen and (min-width: 1170px) {
  .cd-headline {
    font-size: 6rem;
  }
}

.cd-words-wrapper {
  display: inline-block;
  position: relative;
  text-align: left;
}

.cd-words-wrapper b {
  display: inline-block;
  position: absolute;
  white-space: nowrap;
  left: 0;
  top: 0;
}

.cd-words-wrapper b.is-visible {
  position: relative;
}

.no-js .cd-words-wrapper b {
  opacity: 0;
}

.no-js .cd-words-wrapper b.is-visible {
  opacity: 1;
}

/* xclip */
.cd-headline.clip span {
  display: inline-block;
  padding: 0.2em 0;
}

.cd-headline.clip .cd-words-wrapper {
  overflow: hidden;
  vertical-align: top;
}

.cd-headline.clip .cd-words-wrapper::after {
  /* line */
  content: "";
  position: absolute;
  top: 10%;
  right: 0;
  width: 2px;
  height: 70%;
  background-color: #aebcb9;
}

.cd-headline.clip b {
  opacity: 0;
}

.cd-headline b.is-visible {
  opacity: 1;
  font-weight: 900;
}

.owl-theme .owl-dots .owl-dot span {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #f7f8fa;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #f24259;
}

/* ----------------------------------------------------------------
     [ End Basic ]
-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
     [ 02 Start Navbar ]
-----------------------------------------------------------------*/
.navbar {
  width: 100%;
  z-index: 999;
  min-height: 80px;
  padding: 0;
}

.navbar .icon-bar {
  color: #000;
}

.navbar .navbar-nav .nav-link {
  font-size: 16px;
  font-weight: 900;
  color: #000;
  letter-spacing: 0.5px;
  margin: 6px 5px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  font-family: "Raleway", sans-serif;
}

.navbar .navbar-nav .nav-link.active {
  color: #eb1c24;
}

.nav-scroll {
  background: #fff;
  border-bottom: 1px solid rgba(12, 12, 12, 0.12);
  padding: 0;
  position: fixed;
  top: -100px;
  left: 0;
  width: 100%;
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  -webkit-transform: translateY(100px);
  transform: translateY(100px);
}

.nav-scroll .icon-bar {
  color: #222;
}

.nav-scroll .navbar-nav .nav-link {
  color: #222 !important;
}

.nav-scroll .navbar-nav .logo {
  padding: 15px 0;
  color: #111;
}

.shadow-lg {
  box-shadow: 0px 12px 24px -12px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 12px 24px -12px rgba(0, 0, 0, 0.5);
}

.logo {
  padding: 0;
  width: 125px;
}

@media (max-width: 740px) {
  .logo {
    padding: 0;
    width: 100px;
  }
}

/* ----------------------------------------------------------------
     [ End Navbar ]
-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
     [ 03 Start Header ]
-----------------------------------------------------------------*/
.header {
  min-height: 100vh;
  overflow: hidden;
}

.header .caption .o-hidden {
  display: inline-block;
}

.header .caption h3 {
  font-weight: 200;
  letter-spacing: 3px;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.header .caption h1 {
  margin: 10px 0;
  font-size: 70px;
  font-weight: 700;
  text-transform: uppercase;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.header .caption h1 b {
  color: #f24259;
}

.header .caption p {
  font-size: 16px;
  color: #eee;
  word-spacing: 2px;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.header .caption .butn {
  padding: 10px 30px;
  border: 1px solid #eee;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1px;
  position: relative;
  margin: 30px 2px 0;
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

.header .caption .butn:after {
  content: "";
  width: 0;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 100%;
  z-index: 1;
  background: #fff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.header .caption .butn:hover {
  border-color: #fff;
}

.header .caption .butn:hover:after {
  width: 100%;
  left: 0;
  -webkit-transition: width 0.3s ease;
  transition: width 0.3s ease;
}

.header .caption .butn:hover span {
  color: #1c1c1c;
}

.header .caption .butn span {
  position: relative;
  z-index: 2;
}

.header .caption .butn-bg {
  background: #f24259;
  border-color: #f24259;
}

.header .arrow {
  position: absolute;
  bottom: 5vh;
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 13px;
  z-index: 8;
}

.header .arrow i {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.header .arrow i:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  z-index: -1;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.header .arrow i:hover:after {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  opacity: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.slider .arrow,
.slider-fade .arrow {
  display: none !important;
}

.slider .owl-item,
.slider-fade .owl-item {
  height: 100vh;
  position: relative;
}

.slider .item,
.slider-fade .item {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-size: cover;
}

.slider .item .caption,
.slider-fade .item .caption {
  z-index: 9;
}

.slider .owl-theme .owl-dots,
.slider-fade .owl-theme .owl-dots {
  position: absolute;
  bottom: 5vh;
  width: 100%;
}

.creative {
  min-height: 100vh;
}

.creative .svg {
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  z-index: 2;
}

.creative .svg svg {
  fill: #fff;
  width: 101%;
}

.bg-vid {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
}

#particles-js {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}

/* ----------------------------------------------------------------
     [ End Header ]
-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
     [ 04 Start Hero ]
-----------------------------------------------------------------*/
.hero .extra-text {
  font-weight: 200;
  margin-bottom: 25px;
}

.hero .extra-text span {
  font-weight: 700;
}

.hero .item {
  background: #fff;
  text-align: center;
  padding: 30px 15px;
  -webkit-box-shadow: 0px 5px 15px 0px rgba(148, 146, 245, 0.15);
  box-shadow: 0px 5px 15px 0px rgba(148, 146, 245, 0.15);
  border-radius: 5px;
  margin-bottom: 30px;
  min-height: 320px;
}

.hero .item .icon {
  font-size: 50px;
  margin-bottom: 15px;
  color: #f24259;
}

.hero .item h6 {
  margin-bottom: 15px;
}

.hero .mission p {
  margin-bottom: 30px;
  text-align: justify;
}

.hero .mission i {
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #f24259;
  border-radius: 50%;
  color: #fff;
  font-size: 25px;
}

.hero .mission .small-text {
  margin-left: 10px;
  font-size: 13px;
  color: #666;
}

.hero .skills {
  padding-top: 80px;
}

.hero .skills .prog-item {
  margin-bottom: 25px;
}

.hero .skills .prog-item:last-child {
  margin-bottom: 0;
}

.hero .skills .prog-item p {
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 8px;
}

.hero .skills .prog-item .skills-progress {
  width: 100%;
  height: 2px;
  background: #eee;
  border-radius: 5px;
  position: relative;
}

.hero .skills .prog-item .skills-progress span {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: #f24259;
  width: 10%;
  border-radius: 10px;
  -webkit-transition: all 1s;
  transition: all 1s;
}

.hero .skills .prog-item .skills-progress span:after {
  content: attr(data-value);
  position: absolute;
  top: -20px;
  right: 0;
  font-size: 10px;
  color: #777;
}

.cuisine-type-wrap.cuisine-type-wrap {
  width: calc(100% / 10);
  padding-left: 8px;
  padding-right: 8px;
}

.cuisine-pills.cuisine-pills {
  width: fit-content;
  margin-right: 5px;
  border-radius: 40px;
  padding: 5px 15px;
  margin-bottom: 10px;
}

.restaurant-type-wrap {
  max-width: 185px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 5px;
}

.restaurant-type-wrap h6 {
  font-size: 14px;
}

.restaurant-type-wrap .item {
  margin-bottom: 30px;
  transition: all ease-in-out 0.3s;
}

.restaurant-type-wrap .item .services-icon {
  transition: transform 0.3s;
}

.restaurant-type-wrap .item h6 {
  transition: transform 0.8s;
}

.restaurant-type-wrap .item:hover .services-icon {
  transform: translateY(-10%) scale(1.15);
}

.restaurant-type-wrap .item:hover h6 {
  transform: translateY(-30%);
}

.cuisines .services-icon {
  width: 100%;
  height: auto;
  margin-bottom: 0px;
}

.restaurant-info-card .services-icon {
  width: 100%;
  height: 344px;
}

.cuisines .item {
  background: transparent;
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.cuisine-info {
  min-height: 45px;
}

.featured-restaurant .item {
  background-color: #fff;
}

.featured-restaurant-rating {
  position: absolute;
  right: 5px;
  bottom: 5px;
  background-color: #267e3e;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 2px 5px;
  gap: 5px;
  font-weight: 600;
}

.featured-delivery-rating {
  position: absolute;
  right: 5px;
  bottom: 5px;
  background-color: #333;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 2px 5px;
  gap: 5px;
  font-weight: 600;
}

/* ----------------------------------------------------------------
     [ End Hero ]
-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
     [ 05 Start Quote ]
-----------------------------------------------------------------*/
.quote {
  background-attachment: fixed;
}

.quote p .icon {
  font-size: 30px;
  margin: 0 15px;
  padding: 0;
  opacity: 0.3;
  line-height: 1;
}

.quote p {
  font-size: 17px;
  font-style: italic;
}

.quote h5 {
  color: #f24259;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  margin: 15px 0 10px;
}

.quote h6 {
  font-size: 12px;
  color: #ccc;
}

/* ----------------------------------------------------------------
     [ End Quote ]
-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
     [ 06 Start Portfolio ]
-----------------------------------------------------------------*/
.portfolio {
  overflow: hidden;
}

.portfolio .filtering span {
  margin-right: 30px;
  color: #333;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
  border-bottom: 1px solid transparent;
}

.portfolio .filtering span:last-child {
  margin: 0;
}

.portfolio .filtering .active {
  border-color: #f24259;
  color: #f24259;
}

.portfolio .items {
  margin-top: 30px;
}

.portfolio .item-img {
  position: relative;
  overflow: hidden;
}

.portfolio .item-img:hover .item-img-overlay {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.portfolio .item-img:hover h6 {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.portfolio .item-img h6 {
  font-weight: 600;
  position: relative;
  margin-bottom: 5px;
  -webkit-transform: translateY(-15px);
  transform: translateY(-15px);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.portfolio .item-img-overlay {
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  background: rgba(255, 255, 255, 0.95);
  color: #35424c;
  opacity: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  z-index: 2;
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
}

.portfolio .item-img-overlay a {
  font-size: 30px;
  position: absolute;
  bottom: 15px;
  right: 15px;
}

/* ----------------------------------------------------------------
     [ End Portfolio ]
-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
     [ 07 Start Numbers ]
-----------------------------------------------------------------*/
.numbers {
  background-image: url(../images/parallax-bg.webp);
  background-attachment: fixed;
  height: auto;
  padding: 80px 0;
}

.numbers .item .icon {
  font-size: 45px;
  margin-bottom: 10px;
  color: #ccc;
}

.numbers .item h2 {
  font-weight: 800;
  margin-bottom: 15px;
}

.numbers .item h6 {
  font-size: 16px;
  letter-spacing: 1px;
  color: #ccc;
}

@media (max-width: 740px) {
  .numbers {
    background-attachment: fixed;
    height: auto;
  }
}

/* ----------------------------------------------------------------
     [ End Numbers ]
-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
     [ 08 Start services ]
-----------------------------------------------------------------*/
.services .item {
  padding: 30px 15px;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(148, 146, 245, 0.15);
  box-shadow: 0px 5px 10px 0px rgba(148, 146, 245, 0.15);
  border-radius: 5px;
  margin-bottom: 30px;
  text-align: left;
  overflow: auto;
}

.services .item .icon {
  font-size: 30px;
  margin-bottom: 5px;
  color: #f24259;
}

.services .item h6 {
  margin-bottom: 15px;
}

.float-icon {
  position: fixed;
  bottom: 20px;
  z-index: 9;
}

.float-icon.instagram {
  position: fixed;
  left: 50px;
}

.float-icon.facebook {
  position: fixed;
  left: 10px;
}

.float-icon.whatsapp {
  left: 90px;
}

.float-icon.youtube {
  left: 130px;
}

.float-icon.Phone {
  left: 170px;
}

.scroll-top {
  position: fixed;
  bottom: 15px;
  right: 10px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  height: auto;
  width: 70px;
  border: none;
  outline: none;
  background-color: transparent;
  -webkit-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
  display: none;
}

.scroll-top.show {
  display: block;
}

.scroll-top img {
  width: 100%;
  height: auto;
}

.featured-chefs .react-multiple-carousel__arrow {
  background: var(--theme-color-red) !important;
}

.featured-chefs
  .react-multiple-carousel__arrow--left.react-multiple-carousel__arrow--left {
  left: calc(1% + 1px);
  top: calc(30% + 1px);
}

.featured-chefs
  .react-multiple-carousel__arrow--right.react-multiple-carousel__arrow--right {
  right: calc(1% + 1px);
  top: calc(30% + 1px);
}

.featured-events .react-multiple-carousel__arrow {
  background: var(--theme-color-red) !important;
}

.featured-events
  .react-multiple-carousel__arrow--left.react-multiple-carousel__arrow--left {
  left: calc(1% + 1px);
}

.featured-events
  .react-multiple-carousel__arrow--right.react-multiple-carousel__arrow--right {
  right: calc(1% + 1px);
}

/* ----------------------------------------------------------------
     [ End Services ]
-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
     [ 09 Start Services Tabs ]
-----------------------------------------------------------------*/
.serv-tabs {
  background-attachment: fixed;
  background-image: url(../images/dm-solutions-bg.jpg);
}

.serv-tabs::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.serv-tabs .content .tab-item {
  display: none;
  position: relative;
}

.serv-tabs .content .curent {
  display: block;
}

.serv-tabs .content .bord {
  padding-right: 30px;
}

.serv-tabs .content .bord:after {
  content: "";
  width: 1px;
  height: 100%;
  background: #f24259;
  position: absolute;
  right: 1px;
  top: 0;
  opacity: 0.7;
}

.serv-tabs .content .spcial {
  padding-left: 30px;
}

.serv-tabs .content .spcial p {
  color: #fff;
  font-size: 16px;
  font-style: italic;
  word-spacing: 1px;
}

.serv-tabs .tabs-icon .item div {
  text-align: center;
  padding: 30px 20px;
  background: rgba(0, 0, 0, 0.45);
  min-height: 350px;
  border-radius: 5px;
  cursor: pointer;
}

.serv-tabs .tabs-icon .item .icon {
  font-size: 30px;
  color: #f24259;
  margin-bottom: 15px;
}

.serv-tabs .tabs-icon .item h6 {
  font-size: 17px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: capitalize;
  color: #fff;
}

.serv-tabs .tabs-icon .active h6 {
  color: #111;
}

/* ----------------------------------------------------------------
     [ End Services Tabs ]
-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
     [ 10 Start Team ]
-----------------------------------------------------------------*/
.team .titem .team-img {
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  width: 220px;
  height: 220px;
  margin: auto;
}

.team .titem .team-img:hover img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.team .titem img {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.team .titem h6 {
  font-size: 18px;
  font-weight: 600;
  font-family: inherit;
  margin: 20px 0 10px;
}

.team .titem span {
  font-size: 14px;
  color: #959595;
}

.team .titem .social {
  margin: 15px 0;
}

.team .titem .social a {
  margin: 0 5px;
}

/* ----------------------------------------------------------------
     [ End Team ]
-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
     [ 11 Start Price ]
-----------------------------------------------------------------*/
.price .item {
  padding: 30px 0;
  background: #fff;
  border-radius: 5px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.price .item:hover {
  -webkit-box-shadow: 0px 5px 40px 0px rgba(148, 146, 245, 0.2);
  box-shadow: 0px 5px 40px 0px rgba(148, 146, 245, 0.2);
}

.price .type {
  margin-bottom: 30px;
}

.price .type .icon {
  display: none;
  font-size: 45px;
  color: #eee;
  margin-bottom: 10px;
}

.price .type h4 {
  font-size: 25px;
}

.price .value {
  padding: 30px 0;
  position: relative;
}

.price .value:before,
.price .value:after {
  content: "";
  background: #eee;
  width: 70%;
  height: 1px;
  position: absolute;
  left: 15%;
}

.price .value:before {
  top: 0;
}

.price .value:after {
  bottom: 0;
}

.price .value h3 {
  display: inline-block;
  padding-right: 10px;
  font-size: 50px;
  font-weight: 800;
  position: relative;
}

.price .value h3 span {
  font-size: 14px;
  position: absolute;
  top: 5px;
  right: 0;
}

.price .value .per {
  font-size: 13px;
  color: #777;
}

.price .features {
  padding: 15px 0;
}

.price .features li {
  margin: 15px 0;
  color: #777;
}

.price .order {
  padding-top: 15px;
  position: relative;
}

.price .order:before {
  content: "";
  background: #eee;
  width: 70%;
  height: 1px;
  position: absolute;
  left: 15%;
  top: 0;
}

.price .order a {
  background: #f24259;
  border: 1px solid #f24259;
  color: #fff;
  padding: 10px 30px;
  margin-top: 15px;
  font-weight: 600;
  position: relative;
}

.price .order a:after {
  content: "";
  width: 0;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 100%;
  z-index: 1;
  background: #fff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.price .order a span {
  position: relative;
  z-index: 2;
}

.price .order a:hover:after {
  width: 100%;
  left: 0;
  -webkit-transition: width 0.3s ease;
  transition: width 0.3s ease;
}

.price .order a:hover span {
  color: #f24259;
}

.price .active {
  -webkit-box-shadow: 0px 5px 40px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 40px 0px rgba(0, 0, 0, 0.1);
}

/* ----------------------------------------------------------------
     [ End Price ]
-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
     [ 12 Start Testimonails ]
-----------------------------------------------------------------*/
.testimonails {
  background-attachment: fixed;
}

.testimonails .client-img {
  width: 85px;
  height: 85px;
  border-radius: 50%;
  border: 5px solid rgba(255, 255, 255, 0.2);
  overflow: hidden;
  margin: 0 auto 15px;
}

.testimonails h5 {
  color: #f24259;
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 5px;
}

.testimonails h6 {
  font-size: 12px;
  color: #ccc;
  margin-bottom: 30px;
}

.testimonails .owl-theme .owl-dots {
  margin-top: 30px !important;
}

/* ----------------------------------------------------------------
     [ End Testimonails ]
-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
     [ 13 Start Blog ]
-----------------------------------------------------------------*/
.blog .item {
  -webkit-box-shadow: 0px 5px 35px 0px rgba(148, 146, 245, 0.15);
  box-shadow: 0px 5px 35px 0px rgba(148, 146, 245, 0.15);
  border-radius: 5px;
}

.blog .item .post-img {
  position: relative;
  overflow: hidden;
}

.blog .item .post-img:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.blog .item .post-img img {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.blog .item .post-img .date {
  display: inline-block;
  position: absolute;
  top: 15px;
  left: 15px;
  background: #111;
  color: #fff;
  padding: 10px 15px;
  text-align: center;
}

.blog .item .post-img .date span {
  display: block;
  font-weight: 700;
  font-size: 12px;
}

.blog .item .content {
  padding: 40px 5px;
  background: #fff;
}

.blog .item .content .tag {
  color: #f24259;
  font-weight: 600;
  margin-bottom: 10px;
}

.blog .item .content h5 {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 15px;
}

.blog-nav {
  position: static !important;
  background: #fff !important;
  margin: 0;
}

.blog-nav .logo {
  color: #1c1c1c !important;
}

.blog-nav .navbar-nav li a {
  color: #1c1c1c !important;
  font-weight: 500;
}

.min-header {
  height: 50vh;
  background: #1c1c1c;
  position: relative;
}

.min-header .v-middle {
  z-index: 4;
}

.min-header h5 {
  font-weight: 700;
  letter-spacing: 1px;
  color: #ccc;
  margin-bottom: 15px;
}

.min-header .path {
  display: inline-block;
  padding: 5px 10px;
  text-align: center;
  background: rgba(100, 100, 100, 0.2);
}

.min-header span {
  position: relative;
  color: #ddd;
  font-style: italic;
  line-height: 1.7;
}

.min-header span:after {
  content: ">>";
  position: relative;
  margin: 0 10px;
}

.min-header span:last-child:after {
  display: none;
}

.blogs .posts .post {
  margin-bottom: 50px;
  border-bottom: 1px solid #eee;
}

.blogs .posts .post .content {
  padding: 50px 15px;
  background: #fff;
}

.blogs .posts .post .content .post-title h5 {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.6;
  margin-bottom: 10px;
}

.blogs .posts .post .content .meta {
  margin-bottom: 20px;
}

.blogs .posts .post .content .meta li {
  display: inline-block;
  font-size: 12px;
  color: #777;
  margin: 5px;
}

.blogs .posts .post .content p {
  font-weight: 300;
}

.blogs .posts .post .content .spical {
  padding: 15px;
  margin: 30px 0;
  border-left: 2px solid #111;
  background: #f7f7f7;
  font-size: 16px;
}

.blogs .posts .post .content .butn {
  display: inline-block;
  margin-top: 30px;
  padding: 8px 30px;
  border: 2px solid #ddd;
  font-weight: 500;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.blogs .posts .post .content .butn:hover {
  background: #f24259;
  border-color: #f24259;
  color: #fff;
}

.blogs .posts .post .share-post {
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px dashed #eee;
  text-align: left;
}

.blogs .posts .post .share-post span {
  font-weight: 700;
}

.blogs .posts .post .share-post ul {
  float: right;
}

.blogs .posts .post .share-post ul li {
  display: inline-block;
  margin: 0 10px;
}

.blogs .posts .title-g h3 {
  font-weight: 800;
  font-size: 25px;
}

.blogs .posts .comments-area .comment-box {
  padding-bottom: 30px;
  margin-bottom: 50px;
  border-bottom: 1px solid #eee;
}

.blogs .posts .comments-area .comment-box:nth-child(odd) {
  margin-left: 80px;
}

.blogs .posts .comments-area .comment-box:last-child {
  margin-bottom: 0;
}

.blogs .posts .comments-area .comment-box .author-thumb {
  width: 80px;
  float: left;
}

.blogs .posts .comments-area .comment-box .comment-info {
  margin-left: 100px;
}

.blogs .posts .comments-area .comment-box .comment-info h6 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}

.blogs .posts .comments-area .comment-box .comment-info .reply {
  margin-top: 10px;
  font-weight: 600;
}

.blogs .posts .comments-area .comment-box .comment-info .reply i {
  padding-right: 5px;
  font-size: 12px;
}

.blogs .posts .comment-form .form input[type="text"],
.blogs .posts .comment-form .form input[type="email"],
.blogs .posts .comment-form .form textarea {
  width: 100%;
  border: 0;
  border-radius: 5px;
  padding: 10px;
  background: #f4f4f4;
}

.blogs .posts .comment-form .form textarea {
  height: 160px;
  max-height: 160px;
  max-width: 100%;
}

.blogs .posts .comment-form .form button[type="submit"] {
  background: #f24259;
  border: 1px solid #f24259;
  color: #fff;
  padding: 10px 30px;
  margin-top: 15px;
  font-weight: 600;
  cursor: pointer;
  position: relative;
}

.blogs .posts .comment-form .form button[type="submit"]:after {
  content: "";
  width: 0;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 100%;
  z-index: 1;
  background: #fff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.blogs .posts .comment-form .form button[type="submit"] span {
  position: relative;
  z-index: 2;
}

.blogs .posts .comment-form .form button[type="submit"]:hover:after {
  width: 100%;
  left: 0;
  -webkit-transition: width 0.3s ease;
  transition: width 0.3s ease;
}

.blogs .posts .comment-form .form button[type="submit"]:hover span {
  color: #f24259;
}

.blogs .posts .pagination {
  display: block;
  text-align: center;
}

.blogs .posts .pagination li {
  display: inline-block;
  padding: 5px 10px;
  margin: 2px;
  border: 1px solid #ddd;
}

.blogs .side-bar .widget {
  margin-bottom: 50px;
}

.blogs .side-bar .widget:last-child {
  margin-bottom: 0;
}

.blogs .side-bar .widget .widget-title {
  border-bottom: 1px solid #eee;
  margin-bottom: 30px;
}

.blogs .side-bar .widget .widget-title h6 {
  position: relative;
  padding-bottom: 15px;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.blogs .side-bar .widget .widget-title h6:after {
  content: "";
  width: 30px;
  height: 1px;
  background: #000;
  position: absolute;
  bottom: 0;
  left: 0;
}

.blogs .side-bar .widget li {
  margin-bottom: 10px;
  color: #777;
}

.blogs .side-bar .widget li:last-child {
  margin: 0;
}

.blogs .side-bar .search form input {
  width: calc(100% - 52px);
  height: 50px;
  padding: 0 10px;
  border: 0;
  background: #f7f7f7;
}

.blogs .side-bar .search form button {
  width: 50px;
  height: 50px;
  line-height: 50px;
  background: #1c1c1c;
  color: #fff;
  border: 0;
  float: right;
}

/* ----------------------------------------------------------------
     [ End Blog ]
-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
     [ 14 Start Clients ]
-----------------------------------------------------------------*/
.clients {
  padding: 30px 0;
}

.clients .brand {
  padding: 15px;
  opacity: 0.5;
}

.clients .brand:hover {
  opacity: 1;
}

/* ----------------------------------------------------------------
     [ End Clients ]
-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
     [ 15 Start Contact ]
-----------------------------------------------------------------*/
.contact .map {
  padding: 0;
  position: relative;
  z-index: 4;
}

.contact #ieatmaps {
  height: 100%;
  /* background: url(../img/form-bg.jpg); */
  background-position: right;
  background-size: cover;
}

.information {
  background-image: url(../images/parallax-bg.webp);
  padding: 80px 0;
  color: #fff;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.information .item {
  padding: 20px;
  background: rgba(70, 70, 70, 0.3);
  border-radius: 5px;
  cursor: pointer;
  min-height: 181px;
}

.information .info .item:last-child {
  margin-bottom: 0;
}

.information .info .item .icon {
  font-size: 45px;
  float: left;
}

.information .info .item .cont {
  margin-left: 60px;
}

.information .info .item .cont h6 {
  font-size: 16px;
  margin-bottom: 5px;
}

.information .info .item .cont p {
  color: #fff;
}

.information.grub-red {
  background-color: #000;
  background-image: none;
}

.contact .map-show {
  left: -100%;
}

.contact .map-show .icon-toggle {
  z-index: 2;
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.contact .contact-form {
  padding: 53px 0px;
}

.contact .contact-form input[type="text"],
.contact .contact-form input[type="email"],
.contact .contact-form textarea {
  width: 100%;
  padding: 10px;
  border: 0;
  background: #f7f7f7;
  border-radius: 5px;
}

.contact .contact-form textarea {
  height: 160px;
  max-height: 160px;
  max-width: 100%;
}

.sub-button {
  background: #eb1c24;
  border: 1px solid #eb1c24;
  color: #fff;
  padding: 10px 30px;
  margin-top: 15px;
  font-weight: 600;
  cursor: pointer;
  position: relative;
  border-radius: 5px;
}

.sub-button:after {
  content: "";
  width: 0;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 100%;
  z-index: 1;
  background: #fff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 5px;
}

.contact .contact-form button[type="submit"] span {
  position: relative;
  z-index: 2;
}

.contact .contact-form button[type="submit"]:hover:after {
  width: 100%;
  left: 0;
  -webkit-transition: width 0.3s ease;
  transition: width 0.3s ease;
}

.contact .contact-form button[type="submit"]:hover span {
  color: #000000;
}

.abt {
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

/* ----------------------------------------------------------------
     [ End Contact ]
-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
     [ 16 Start Footer ]
-----------------------------------------------------------------*/
footer {
  background: var(--theme-color-red);
  color: var(--themecolor-white);
  padding: 20px 0;
}

.footer .social a {
  color: #777;
  font-size: 18px;
  margin: 15px 10px;
}

footer span {
  color: #777;
  font-weight: 700;
}

.extra-text.title-right {
  margin-bottom: 0 !important;
  border-left: none;
}

.extra-text.title-right:before {
  display: none;
}

.like-and-rating {
  bottom: 0;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 8px;
  opacity: 0.9;
}

/* ----------------------------------------------------------------
     [ End Footer ]
-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
 	 [ 17 Responsive ]
-----------------------------------------------------------------*/
@media screen and (max-width: 991px) {
  .mb-md50 {
    margin-bottom: 50px;
  }

  .mb-md30 {
    margin-bottom: 30px;
  }

  .mb-md0 {
    margin-bottom: 0;
  }

  .navbar .navbar-collapse {
    max-height: unset;
    overflow: auto;
    background: #111;
    text-align: center;
    padding: 10px 0;
    height: auto;
  }

  .navbar .nav-link {
    margin: 10px auto !important;
  }

  .nav-scroll .navbar-collapse .nav-link {
    color: #fff !important;
  }

  .nav-scroll .navbar-collapse .active {
    color: #f24259 !important;
  }

  .header .caption h2 {
    font-size: 30px;
  }

  .header .caption h1 {
    font-size: 50px;
  }

  .header .caption p {
    font-size: 16px;
  }

  .serv-tabs .content .bord {
    padding-right: 0;
    margin-bottom: 50px;
  }

  .serv-tabs .content .bord:after {
    display: none;
  }

  .serv-tabs .content .spcial {
    padding-left: 0;
  }

  .contact .map {
    height: 400px;
  }

  .information .info {
    margin-bottom: 30px;
  }

  .information {
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 767px) {
  .mb-sm50 {
    margin-bottom: 50px;
  }

  .mb-sm30 {
    margin-bottom: 30px;
  }

  .nav-scroll {
    padding-left: 0px;
  }

  .header .caption h3 {
    font-size: 20px;
  }

  .header .caption h1 {
    font-size: 30px;
  }

  .serv-tabs .tabs-icon .item div {
    padding: 15px 15px;
    margin-bottom: 10px;
  }

  .serv-tabs .tabs-icon .item h6 {
    display: none;
  }

  .serv-tabs .tabs-icon .item div {
    text-align: center;
    padding: 30px 20px;
    background: rgba(70, 70, 70, 0.55);
    height: auto;
    border-radius: 5px;
    cursor: pointer;
  }

  .section-padding {
    padding: 10px 0;
  }

  .scroll-top {
    bottom: 10px;
    right: 5px;
  }

  .float-icon.facebook {
    left: 10px;
  }

  .float-icon.instagram {
    left: 50px;
  }
}

.banner-list li {
  display: inline;
  color: #fff;
  font-weight: 300;
  margin-right: 5px;
  font-size: 15px;
}

.banner-list {
  margin-top: 10px;
}

.mt-130 {
  margin-top: 200px;
}

.nav-scroll .logo {
  padding: 15px 0 !important;
  width: 200px;
}

.design {
  text-align: left;
  margin-bottom: 0;
  color: var(--themecolor-white);
}

.copyright {
  text-align: right;
  float: right;
  color: var(--themecolor-white);
  margin-bottom: 0;
}

.how {
  width: 50px;
  padding-bottom: 20px;
}

/* Effect 1: Brackets */
.cl-effect-1 a::before,
.cl-effect-1 a::after {
  display: inline-block;
  opacity: 0;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.2s;
  -moz-transition: -moz-transform 0.3s, opacity 0.2s;
  transition: transform 0.3s, opacity 0.2s;
}

.cl-effect-1 a::before {
  margin-right: 10px;
  content: "[";
  -webkit-transform: translateX(20px);
  -moz-transform: translateX(20px);
  transform: translateX(20px);
}

.cl-effect-1 a::after {
  margin-left: 10px;
  content: "]";
  -webkit-transform: translateX(-20px);
  -moz-transform: translateX(-20px);
  transform: translateX(-20px);
}

.cl-effect-1 a:hover::before,
.cl-effect-1 a:hover::after,
.cl-effect-1 a:focus::before,
.cl-effect-1 a:focus::after {
  opacity: 1;
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  transform: translateX(0px);
}

.hero-container {
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.hero-container .extra-text {
  font-weight: 200;
  margin-bottom: 10px;
}

.hero-container .extra-text span {
  font-weight: 700;
}

.hero-container-homebg .extra-text {
  font-weight: 200;
  margin-bottom: 10px;
  text-shadow: 0px 0px 30px #000;
}

.hero-container-homebg .extra-text span {
  font-weight: 700;
  text-shadow: 0px 0px 30px #000;
}

.extra-text {
  font-weight: 200;
  margin-bottom: 10px;
}

.extra-text span {
  font-weight: 700;
}

p.how-it-works {
  color: #fff;
  margin-top: 10px;
  text-align: justify;
}

.privacy-box {
  text-align: center;
  padding: 30px 10px;
  background: rgba(70, 70, 70, 0.92);
  border-radius: 5px;
  cursor: pointer;
}

.grub-red.grub-red {
  color: #eb1c24;
  font-weight: 700;
}

.grub-green {
  color: #008000;
  font-weight: 700;
}

.services-icon {
  width: 60px;
  height: auto;
  margin-bottom: 30px;
}

.restaurant-type-wrap.restaurant-type-wrap {
  width: calc(100% / 10);
  margin-bottom: 2rem;
}

.restaurant-type-wrap-home {
  width: calc(100% / 9) !important;
  margin-bottom: 0 !important;
}

.restaurant-type-wrap .services-icon {
  width: 60px;
  height: auto;
  margin-bottom: 20px;
}

.restaurant-type-wrap-home .services-icon {
  width: 60px !important;
  height: auto !important;
  margin-bottom: 20px !important;
}

.contact-image {
  width: 500px;
  height: auto;
}

.contact-block {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Penguin {
  position: absolute;
  bottom: -50px;
  right: -100px;
  width: 300px;
}

.font-special {
  font-size: 24px;
}

.about-des {
  width: 75%;
}

.hero-container-homebg {
  /* background-image: url(../images/restauranthomebg.jpg); */
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  position: relative;
}

.hero-container-homebg .search-section {
  position: absolute;
  bottom: -10%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.hero-container-homebg .search-section-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.search-input {
  display: flex;
  align-items: self-start;
}

.search-input .search-input-text,
.search-input input {
  width: 100%;
  height: 40px;
  padding-left: 2.3rem;
  padding-right: 10px;
  border-radius: 6px;
  border: 1px solid #e3e3e3;
}

.search-input .search-input-text {
  overflow: hidden;
}

.search-input .search-input-text span {
  display: flex;
  width: 150%;
  align-items: center;
  height: 100%;
}

.search-input .search-icon {
  height: 30px;
  width: 30px;
  background-color: #eb1c24;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 5px;
  margin: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-icon .search-icon-svg {
  font-size: 26px;
  color: #fff;
}

.search-offcanvas {
  height: 80vh !important;
  padding-bottom: 1rem;
}

.search-offcanvas .search-input input {
  padding-left: 2.4rem !important;
}

.search-offcanvas .search-input .search-icon {
  left: 0;
}

.search-result-card {
  padding: 10px;
  border-bottom: 1px solid #e3e3e3;
  text-align: left;
}

.search-result-pc {
  position: absolute;
  top: 100%;
  right: 0;
  overflow-y: auto;
  width: 65%;
  display: none;
}

.search-result-pc-show {
  display: block;
  animation: 0.7s serachbox;
  background: #fff;
  height: 45vh;
}

@keyframes serachbox {
  0% {
    height: 0;
  }

  100% {
    height: 45vh;
    /* height: auto; */
  }
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.no-text-shadow {
  text-shadow: none !important;
}

.min-height-auto {
  min-height: auto !important;
}

.react-multiple-carousel__arrow {
  z-index: 0 !important;
}

.social-icon {
  font-size: 30px;
  background-color: #fff;
  border-radius: 5px;
}

.social-icon.facebook {
  color: #1778f2;
}

.social-icon.instagram {
  color: #c13584;
}

.social-icon.whatsapp {
  color: #25d366;
}

.social-icon.youtube {
  color: #ff0000;
}

.social-icon.Phone {
  color: #009900;
}

.restaurant_type .hero .item h6 {
  font-size: 16px;
  line-height: 26px;
  padding-top: 10px;
}

.react-tabs__tab {
  font-size: 18px;
}

.tab-section-title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 20px;
  color: var(--theme-color-red);
}

.search-section {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.search-section .search-card {
  width: 100%;
}

.filter-card .search-icon svg {
  font-size: 36px;
  color: var(--theme-color-red);
}

.min-vh-50 {
  min-height: 50vh !important;
}

.filter-label {
  margin-bottom: 0;
  color: var(--theme-color-red);
  font-family: 400;
}

.filter-card.filter-card {
  width: 135px;
  padding: 5px 1.1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.filter-card.filter-card.clear-filter {
  width: 125px;
  padding: 5px 0;
}

.px-lg-6 {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.search-input .form-control {
  padding: 0.1rem 0.75rem;
}

.form-control:focus {
  box-shadow: 0 0 0 0 !important;
}

input:focus {
  box-shadow: 0 0 0 0 !important;
}

.ant-select-item-option-content a {
  color: var(--themecolor-black);
  text-decoration: none;
}

.offcanvas-body .hero a {
  width: 100%;
  color: var(--themecolor-black);
  text-decoration: none;
}

.offcanvas-body .hero a .services-icon {
  width: 80%;
  height: auto;
  margin-bottom: 10px;
  border-radius: 10px;
}

.offcanvas-body .hero .item {
  padding: 15px 5px;
}

.offcanvas-body .hero a .services-icon-name {
  font-size: 14px;
  margin-bottom: 0;
}

.dish-image {
  border-radius: 10px !important;
  width: 70px;
}

.ant-select-dropdown {
  box-shadow: 0 0 0 0 !important;
  z-index: 0;
}

.ant-select:hover .ant-select-selector {
  border-color: #ffffff !important;
}

.ant-select-focused .ant-select-selector {
  border-color: #ffffff !important;
  box-shadow: 0 0 0 0 rgba(5, 145, 255, 0.1) !important;
  outline: 0 !important;
}

.clear-filter-desktop {
  display: block !important;
}

.clear-filter-mobile {
  display: none !important;
}

/* 
.card-cuisine-list {
  height: 45px;
  margin-bottom: 0 !important;
} */
.card-cuisine-list {
  /* height: 45px; */
  margin-bottom: 0 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.digital-menu .search-box .search-icon {
  position: absolute;
  bottom: 50%;
  right: 3%;
  transform: translateY(50%);
  font-size: large;
  color: var(--theme-color-red);
}

.search-section .search-card {
  position: relative;
  z-index: 9;
}

@media (max-width: 1300px) {
  .filter-card.filter-card {
    width: 140px;
  }
}

@media (max-width: 1140px) and (min-width: 1050px) {
  .restaurant-type-wrap-home {
    width: calc(100% / 6) !important;
  }
}

@media (max-width: 1040px) and (min-width: 993px) {
  .restaurant-type-wrap-home {
    width: calc(100% / 5) !important;
  }
}

@media (max-width: 992px) {
  .search-result-pc {
    width: 100%;
    height: 60vh;
  }

  .restaurant-type-wrap-main {
    overflow-x: auto;
    flex-wrap: nowrap !important;
  }

  .restaurant-type-wrap-main .restaurant-type-wrap-home,
  .restaurant-type-wrap-main .cuisine-type-wrap {
    min-width: 55%;
    width: calc(100% / 2) !important;
  }

  .restaurant-type-wrap-main .cuisine-type-wrap {
    min-width: 55%;
  }

  .navbar-box {
    width: 100%;
    background-color: #fff;
  }

  .hero-container {
    height: auto;
  }

  .Penguin {
    z-index: 99;
  }

  .search-section {
    flex-wrap: wrap;
  }

  .clear-filter-desktop {
    display: none !important;
  }

  .clear-filter-mobile {
    display: block !important;
  }

  .search-section .filter-card {
    margin-top: 1rem;
    /* width: 20%; */
    padding: 10px;
    height: auto;
    margin-left: 1rem;
  }
}

@media (max-width: 740px) {
  .copyright {
    text-align: center;
    float: none;
  }

  .design {
    text-align: center;
    float: none;
    margin-bottom: 0;
  }

  /* .contact .contact-form {
    padding: 53px 15px;
  } */
  .how {
    width: 80px;
    padding-bottom: 0px;
  }

  .hero-container .extra-text {
    font-size: 32px;
  }

  .navbar .navbar-nav .nav-link {
    color: #fff;
  }

  .contact-image {
    width: 100%;
    height: auto;
  }

  .Penguin {
    position: absolute;
    bottom: -58px;
    right: -57px;
    width: 165px;
  }

  .hero-container {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .about-des {
    width: 100%;
  }

  .restaurant_type .hero .item h6 {
    font-size: 12px;
  }

  .restaurant_type .hero .item h6 {
    font-size: 14px;
    font-weight: bold;
  }

  .restaurant-info-card .services-icon {
    height: 364px;
    width: 100%;
  }

  .section-head h4 {
    border-left: 1px solid #eee;
    font-size: 22px;
  }

  .footer {
    background: var(--theme-color-red);
    color: var(--themecolor-white);
    padding: 0 0 40px;
  }

  .section-head {
    margin-bottom: 20px;
  }

  .search-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .search-section .search-card {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .filter-card.filter-card {
    width: 30%;
    height: auto;
    /* margin-left: 0 !important; */
    margin-top: 10px;
  }
}

.form-select:focus {
  box-shadow: none !important;
  border-color: #eb1c24 !important;
}

.lets-contact-address p {
  margin-bottom: 0;
  padding-top: 2px;
}

.overflow-x-clip {
  overflow-x: clip !important;
}

.digital-menu .filter-row.filter-row {
  display: flex;
  width: 85%;
  gap: 10px;
  overflow-x: auto;
  align-items: center;
  padding-bottom: 15px;
  padding-top: 5px;
  flex-wrap: nowrap;
}

.digital-menu.filter-row .nav-link {
  text-wrap: nowrap;
}

.digital-menu .drag-icon {
  display: none;
}

.digital-menu .digital-menu-item-img {
  width: 75px;
  margin-right: 10px;
}

.digital-menu .menu-item-title {
  width: 90%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.digital-menu .menu-item-name-wrapper {
  max-width: 70%;
}

.digital-menu .offer-image {
  border-radius: 10px;
  overflow: hidden;
}

.digital-menu .react-multi-carousel-list {
  padding-bottom: 25px;
}

.digital-menu .react-multi-carousel-dot-list {
  position: absolute;
  bottom: 0;
  display: flex;
  left: 0;
  right: 0;
  justify-content: center;
  margin: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.digital-menu .react-multi-carousel-dot button {
  border-color: var(--theme-color-red) !important;
}

.digital-menu .react-multi-carousel-dot--active button {
  background: var(--theme-color-red) !important;
}

@media (max-width: 1480px) and (min-width: 1100px) {
  .search-bar h4 {
    font-size: 1.3rem;
  }
}

@media (max-width: 1480px) and (min-width: 1090px) {
}

.filter-row.filter-row {
  display: flex;
  width: 85%;
  gap: 10px;
  overflow-x: auto;
  align-items: center;
  padding-bottom: 15px;
  padding-top: 5px;
  flex-wrap: nowrap;
}

.filter-row .nav-link {
  text-wrap: nowrap;
}

.drag-icon {
  display: none;
}

.digital-menu-item-img {
  width: 75px;
  margin-right: 10px;
}

.menu-item-title {
  width: 90%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.menu-item-name-wrapper {
  max-width: 70%;
}

.offer-image {
  border-radius: 10px;
  overflow: hidden;
}

.react-multi-carousel-list {
  padding-bottom: 25px;
}

.react-multi-carousel-dot-list {
  position: absolute;
  bottom: 0;
  display: flex;
  left: 0;
  right: 0;
  justify-content: center;
  margin: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.react-multi-carousel-dot button {
  border-color: var(--theme-color-red) !important;
}

.react-multi-carousel-dot--active button {
  background: var(--theme-color-red) !important;
}

.restaurant-type-wrap .services-icon {
  width: 100%;
  margin-bottom: 10px;
}

.facilities-type-wrap .item {
  padding: 1rem;
  padding-bottom: 10px;
}

.facilities-type-wrap .services-icon {
  width: 50%;
}

@media screen and (max-width: 768px) {
  .w-75.w-75 {
    width: 100% !important;
  }

  .drag-icon {
    position: absolute;
    width: 50px;
    right: 7px;
    top: 10%;
    display: block;
  }

  .menu-item-name-wrapper {
    max-width: 60%;
  }

  .digital-menu .recipe-img-wrapper {
    width: auto;
    height: auto;
    overflow: hidden;
    border-radius: 10px;
  }

  .recipe-img-wrapper {
    width: 100%;
    height: 200px;
    overflow: hidden;
    border-radius: 10px;
  }

  .recipe-img {
    width: 100%;
    height: auto;
  }

  .item-card {
    flex-direction: column !important;
  }

  .food-type-filter-row {
    padding-bottom: 15px;
  }

  .sticky-search-bar {
    position: sticky;
    top: 60px;
    z-index: 10;
    transition: all ease-in-out 1s;
  }

  .restaurant-type-wrap.restaurant-type-wrap {
    width: calc(100% / 3) !important;
  }

  .restaurant-type-wrap.restaurant-type-wrap-detail {
    width: calc(100% / 2) !important;
    margin-bottom: 2rem !important;
  }
}

.homepage-video {
  object-fit: cover !important;
  object-position: center;
  height: 100%;
  width: 100%;
}

.services .item.selected {
  background: var(--theme-color-red);
  color: var(--themecolor-white);
}

.four-o-four {
  height: 90vh;
}

.error-image {
  width: 30%;
}

.skeleton-cuisine-image {
  height: 150px !important;
  width: 150px !important;
}

.margin-bottom-3 {
  margin-bottom: 3rem;
}

.product-share-btns {
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  z-index: 2;
}

.product-share-btns ul {
  display: flex;
  margin: 0;
  padding: 0;
  flex-direction: column;
  list-style: none;
}

.product-share-btns .p-share-btn,
.product-share-btns ul li {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  /* color: var(--theme-color-red) !important;
  background: var(--themecolor-white) !important; */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  font-size: 1.3rem;
}

.product-share-btns .p-share-btn {
  position: relative;
  z-index: 999;
  background: var(--theme-color-red) !important;
  color: var(--themecolor-white) !important;
}

.product-share-btns ul li {
  position: absolute;
  top: -10px;
  opacity: 0;
  transition: 0.7s;
}

.activ-share-btn .p-share-btn {
  color: var(--theme-color-red) !important;
  background: var(--themecolor-white) !important;
}

.activ-share-btn li:nth-child(5),
.activ-share-btn li:nth-child(2),
.activ-share-btn li:nth-child(3),
.activ-share-btn li:nth-child(4),
.activ-share-btn li:nth-child(6) {
  opacity: 1;
}

.activ-share-btn li:nth-child(2) {
  top: -50px;
}

.activ-share-btn li:nth-child(3) {
  top: -88px;
}

.activ-share-btn li:nth-child(4) {
  top: -127px;
}

.activ-share-btn li:nth-child(5) {
  top: -166px;
}

.activ-share-btn li:nth-child(6) {
  top: -205px;
}

.product-share-btns .p-share-btn {
  cursor: pointer;
  margin-top: 0;
}

.mobile-list-business-btn {
  position: fixed;
  left: 47%;
  bottom: 1.1rem;
  transform: translateX(-47%);
  z-index: 2;
}

.mobile-list-business-btn button {
  font-size: 1rem;
}

.Onboarding-Form .form-floating > label {
  left: 12px !important;
}

.SignUpImage-OnBoarding .onbaordsignupimage {
  width: 100px !important;
}

.top-bar {
  background: var(--theme-color-red);
  padding: 5px 0;
  text-align: center;
  position: relative;
}
.top-bar p {
  color: var(--themecolor-white);
  font-size: 1rem;
}
.top-bar marquee {
  padding-right: 2rem;
}
.top-bar svg {
  color: var(--themecolor-white);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
  padding-left: 4px;
  font-size: 1.8rem;
  cursor: pointer;
  background: var(--theme-color-red);
}
nav.navbar.sticky-top {
  top: -2px !important;
}
.home-parallax {
  height: auto;
  padding: 80px 0;
  background: url("https://grubdigest.com/static/media/parallax-bg.c968545153587a39a86d.webp");
  background-attachment: fixed;
  color: var(--themecolor-white);
}

.home-parallax p {
  color: var(--themecolor-white);
}

.food-courts-section .food-court-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--themecolor-white);
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 5px 10px 0px rgba(148, 146, 245, 0.15);
}
.food-courts-section .food-court-card .content {
  padding: 0 7px;
}
.food-courts-section .food-court-card img {
  aspect-ratio: 1/0.5;
  margin-bottom: 10px;
  object-fit: cover;
}

.food-courts-section .food-court-card .sm-icons {
  padding: 0 7px;
}
.food-courts-section .food-court-card .sm-icons ul {
  display: flex;
  gap: 12px;
  padding: 0;
  margin-top: 1rem;
}
.food-courts-section .food-court-card .sm-icons ul svg {
  font-size: 20px;
  color: var(--theme-color-red);
}

/* chief */
.chef-image img {
  border-radius: 50%;
}

.chief-section .sm-icons ul {
  display: flex;
  gap: 12px;
  padding: 0;
  margin-top: 1rem;
}
.chief-section ul svg {
  font-size: 20px;
  color: var(--theme-color-red);
}

.chief-section .chef-card {
  display: flex;
}
.chief-section .chef-card .chef-image {
  width: 40%;
}
.chief-section .chef-card .content {
  padding: 1rem;
  padding-left: 2rem;
  width: 60%;
}

.news-section .news-left-card {
  border-radius: 10px;
  overflow: hidden;
  background: var(--themecolor-white);
  box-shadow: 0px 5px 10px 0px rgba(148, 146, 245, 0.15);
}
.news-section .news-left-card .image img {
  aspect-ratio: 1/0.56;
  object-fit: cover;
}
.news-section .news-left-card .content {
  padding: 10px;
}
.news-section .news-rigth-card-box .news-rigth-card {
  padding: 10px;
  background: var(--themecolor-white);
  border-radius: 10px;
  display: flex;
  gap: 10px;
  margin-bottom: 1.5rem;
  box-shadow: 0px 5px 10px 0px rgba(148, 146, 245, 0.15);
}
.news-section .news-rigth-card-box .news-rigth-card:last-child {
  margin-bottom: 0;
}

.news-section .news-rigth-card-box .news-rigth-card .image {
  width: 20%;
}
.news-section .news-rigth-card-box .news-rigth-card .image img {
  border-radius: 5px 0 0 5px;
}

.news-section .news-rigth-card-box .news-rigth-card .content {
  width: 80%;
}
.bottom-news-card {
  background: var(--themecolor-white);
  box-shadow: 0px 5px 10px 0px rgba(148, 146, 245, 0.15);
  border-radius: 10px;
  overflow: hidden;
}
.bottom-news-card .image img {
  aspect-ratio: 1/0.5;
  object-fit: cover;
}
.bottom-news-card .content {
  padding: 10px;
}

.bottom-news-card .content h5 {
  font-weight: bold;
}
.blog-section .home-blog-card {
  padding: 10px;
  background: var(--themecolor-white);
  border-radius: 10px;
  box-shadow: 0px 5px 10px 0px rgba(148, 146, 245, 0.15);
}
.blog-section .home-blog-card img {
  border-radius: 5px;
  aspect-ratio: 1/.7;
  object-fit: cover;
  margin-bottom: 10px;
}
.blog-section .home-blog-card .content p {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.blog-section .home-blog-card .content .bottom-btn {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #e3e3e3;
  display: flex;
  justify-content: space-between;
  /* align-items: flex-end; */
}
.blog-section .home-blog-card .content .bottom-btn span {
  display: flex;
  align-items: center;
  gap: 10px;
}
.blog-section .home-blog-card .content .bottom-btn span svg {
  color: var(--theme-color-red);
}

.blog-section .home-blog-card .content .bottom-btn a {
  padding: 5px 10px;
  background: var(--theme-color-red);
  color: var(--themecolor-white);
  border-radius: 5px;
}
