.average-cost p {
  font-size: 16px;
}
.restaurant-info-section-list {
  columns: 2 200px;
}
.restaurant-info-section-list li {
  margin-bottom: 1rem;
  position: relative;
  padding-left: 1.5rem;
  font-size: 16px;
}
.restaurant-info-section-list li::before {
  content: "\f058";
  position: absolute;
  left: 0;
  top: 0;
  font-family: "Font Awesome\ 5 Free";
  color: #eb1c24;
  font-size: 18px;
}

.btn.btn-outline-danger:hover svg {
  color: #fff !important;
}

.font-2x {
  font-size: 16px !important;
}

.quick-contact {
  margin-top: 35px;
}

.social-block a {
  margin-right: 10px;
  padding: 5px;
  border-radius: 5px;
  align-items: center;
  height: 40px;
}

.social-share.social-share {
  font-size: 25px;
  color: #eb1c24;
}

.social-media-share.social-media-share {
  font-size: 25px;
  color: #b9b4b5;
}

.rating-star {
  font-size: 18px;
  color: #eb1c24;
}

.rating-block {
  display: flex;
  background-color: #f9f9f9;
  border-radius: 5px;
  padding: 5px 10px;
  align-items: center;
  gap: 5px;
  width: fit-content;
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-size: 18px;
}

.like-block {
  display: flex;
  background-color: #f9f9f9;
  border-radius: 5px;
  padding: 5px 10px;
  align-items: center;
  gap: 5px;
  width: fit-content;
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-size: 18px;
  margin-right: 0.5rem;
}

.reviews-block {
  display: flex;
  background-color: #f9f9f9;
  border-radius: 5px;
  padding: 5px 10px;
  align-items: center;
  gap: 5px;
  width: fit-content;
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-size: 18px;
  margin-left: 0.5rem;
}

.social-share.action-btn {
  margin-right: 5px;
}

.social-share.action-btn.rotate-icon {
  transform: rotate(-35deg);
}

.contact-restaurant-name {
  font-size: 25px;
  font-weight: bold;
}

.contact-address {
  display: flex;
  gap: 10px;
}

.contact-mobile {
  display: flex;
  gap: 10px;
}

.contact-detail {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.contact-detail div:first-child {
  min-width: 60px;
  font-weight: 600;
  color: #666;
}

.open,
.closed {
  width: 80px;
  height: auto;
}

.offcanvas.offcanvas-end.review-canvas {
  width: 650px;
}

.review-profile-pic {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  margin-right: 10px;
}

.review-canvas .like-block,
.review-canvas .rating-block {
  font-size: 12px;
}
.menu-image-overlay::before {
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
}

.skeleteon-image-height {
  height: 330px !important;
}
.menu-image-cover-fit {
  object-fit: cover;
  height: inherit;
}

.action-button-wrap {
  display: flex;
}
@media screen and (max-width: 992px) {
  .restaurant-type-wrap.restaurant-type-wrap{
    width: calc(100% / 3); 
  }
}
@media screen and (max-width: 768px) {
  .social-block a:first-child {
    margin-left: 0px;
    padding: 5px;
    border-radius: 5px;
    margin-right: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn {
    width: 100%;
  }
  .restaurant-type-wrap.restaurant-type-wrap {
    width: calc(100% / 2);
  }

  .cuisine-type-wrap.cuisine-type-wrap {
    width: calc(100% / 2);
  }
  .action-button-wrap {
    display: flex;
    flex-wrap: wrap;
  }
  .social-block a {
    /* width: 40%; */
    width: 47px !important;
    height: 47px;
    align-items: center;
    justify-content: center;
  }
  .social-block a:last-child{
    /* height: 100%; */
  }
  .social-block .social-action {
    width: auto;
  }
  .btn {
    width: auto;
  }
}
