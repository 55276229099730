@font-face {
    font-family: playlist;
    src: url(../../assets/fonts/Playlist\ Script.otf);
}
.playlist-font{
    font-family: playlist;
    font-size: 3rem;
    color: var(--theme-color-red);
}
.chef-icon{
    width: 100px;
}
.qr-bg{
     display: flex;
    justify-content: center;
    position: relative;
    max-width: 400px;
    height: 400px;
    margin-left: 3rem;
}
.qr-bg img{
    width: 50%;
    height: 50%;
    margin-top: 45px;
}
.qr-bg .qr-frame{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
}
.footer-logo{
    width: 100px;
}
@media (max-width:568px) {
    .qr-bg {
        margin: 0;
    }
}